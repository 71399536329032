import { AxiosError, isAxiosError } from "axios";
import { useErrorLogger } from "datadog";
import { useRouter } from "next/router";
import React from "react";

import { ClientError } from "./ClientError";
import { NetworkError } from "./NetworkError";

export default function RetryError({
  error,
  resetErrorBoundary
}: {
  error: Error | AxiosError;
  resetErrorBoundary: (...args: unknown[]) => void;
}) {
  const router = useRouter();
  const logError = useErrorLogger();

  const handleRoutePageWithoutQuery = () => {
    setTimeout(() => {
      resetErrorBoundary();
    }, 100);
    router.push(router.pathname);
  };
  logError(error);

  const isNetworkError = isAxiosError(error);

  return (
    <>
      {isNetworkError && (
        <NetworkError
          handleRoutePageWithoutQuery={handleRoutePageWithoutQuery}
          resetErrorBoundary={resetErrorBoundary}
          error={error as AxiosError}
        />
      )}
      {!isNetworkError && <ClientError />}
    </>
  );
}
