import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { settings } from "configs/settings";
import MenuItem from "layout/drawer/MenuItem";
import { useMemo } from "react";

import { useRecoilValue } from "recoil";
import { AccountState } from "store/accountRecoil";

function UserInfoItem() {
  const account = useRecoilValue(AccountState);

  const userInfoTitle = useMemo(
    () => (account ? `${account.name} (${account.role})` : ""),
    [account]
  );

  return <MenuItem icon={<AccountCircleIcon />} title={userInfoTitle} />;
}

export default UserInfoItem;
