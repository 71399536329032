import { teal, blue } from "@mui/material/colors";
import { koKR as corekoKR } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { koKR } from "@mui/x-date-pickers";
import { settings } from "configs/settings";

const mode = settings.MODE;

const isLocalOrDevelopment = mode === "development" || mode === "local";

const theme = isLocalOrDevelopment
  ? {
      palette: {
        primary: {
          main: teal[500],
          extraLight: teal[50]
        }
      }
    }
  : {
      palette: {
        primary: {
          main: blue[500],
          extraLight: blue[50]
        }
      }
    };

const mdTheme = createTheme(theme, koKR, corekoKR);

export default mdTheme;
