import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { settings } from "configs/settings";
import Link from "next/link";
import { ToolBarProps } from "types/layout.type";

function AppDrawer(props: ToolBarProps & { children: React.ReactNode }) {
  const { drawerWidth, open, handleDrawer, children } = props;
  const isMobileSize = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box"
        }
      }}
      variant={isMobileSize ? "temporary" : "persistent"}
      anchor="left"
      open={open}
      onClose={handleDrawer}
    >
      <Toolbar
        variant="dense"
        sx={{
          backgroundColor: "primary.dark"
        }}
      >
        <Link href={settings.ORIGIN_REACT_OPS}>
          <Button disableRipple>
            <img src="/image/logo.png" width="100%" />
          </Button>
        </Link>
      </Toolbar>
      <Divider />
      {children}
      <Divider />
    </Drawer>
  );
}

export default AppDrawer;
